import styled from "styled-components/macro"
import { device } from "../../utils/styledUtils"

const Container = styled.div`
  margin: 0 auto;
  @media ${device.desktop} {
    max-width: 1140px;
  }
`

export default Container
