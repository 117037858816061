import styled from "styled-components/macro"

const Meta = styled.div`
  color: #8f8f8f;
  font-size: 0.8em;
  margin-bottom: 1em;
  span:after {
    content: "";
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: #8f8f8f;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    position: relative;
    top: -3px;
  }
  span:last-child:after {
    display: none;
  }
`
export default Meta
