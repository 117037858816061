import React from "react"
import styled from "styled-components/macro"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Renderer from "../../features/Blocks/Renderer"

import SEO from "@components/molecules/SEO"
import Meta from "@components/atoms/Meta"
import Container from "@components/atoms/Container"

const StyledArticle = styled.article`
  padding: 3em;
  h1 {
    margin: 0 0 0.5em;
  }
  figure {
    margin: 0;
  }
  a {
    font-weight: bold;
  }
`

export const query = graphql`
  query ArticleQuery($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      title
      description
      publishedAt(fromNow: true)
      blocks {
        ...Blocks
      }
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      imageCredit
    }
  }
`

export default function Article({ data }) {
  console.log(data)
  const article = data.strapiArticle
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    shareImage: article.cover,
  }

  return (
    <>
      <SEO seo={seo} />
      <Container>
        <StyledArticle>
          <h1>{article.title}</h1>
          <Meta>
            <span>Published {article.publishedAt}</span>
            {/* <span>{timeToRead} min read</span> */}
          </Meta>

          {article.cover && (
            <figure>
              <GatsbyImage
                image={getImage(article?.cover?.localFile)}
                alt={article?.cover?.alternativeText}
                loading="eager"
              />
              {article.imageCredit && (
                <figcaption
                  dangerouslySetInnerHTML={{
                    __html: article.imageCredit,
                  }}
                />
              )}
            </figure>
          )}

          <Renderer blocks={article.blocks || []} />
        </StyledArticle>
      </Container>
    </>
  )
}
