const size = {
  mobile: "767px",
  tablet: "768px",
  desktop: "1024px",
}

// =mobile
// until 768px
// =tablet
// from 769px
// =tablet-only
// from 769px and until 1023px
// =touch
// until 1023px
// =desktop
// from 1024px
// =desktop-only
// from 1024px and until 1215px
// =widescreen
// from 1216px
// =widescreen-only
// from 1216px and until 1407px
// =fullhd
// from 1408px

// =from($device)
//   @media screen and (min-width: $device)
//     @content

// =until($device)
//   @media screen and (max-width: $device - 1px)
//     @content

// =mobile
//   @media screen and (max-width: $tablet - 1px)
//     @content

// =tablet
//   @media screen and (min-width: $tablet), print
//     @content

// =tablet-only
//   @media screen and (min-width: $tablet) and (max-width: $desktop - 1px)
//     @content

// =touch
//   @media screen and (max-width: $desktop - 1px)
//     @content

// =desktop
//   @media screen and (min-width: $desktop)
//     @content

// =desktop-only
//   @if $widescreen-enabled
//     @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px)
//       @content

// =until-widescreen
//   @if $widescreen-enabled
//     @media screen and (max-width: $widescreen - 1px)
//       @content

// =widescreen
//   @if $widescreen-enabled
//     @media screen and (min-width: $widescreen)
//       @content

// =widescreen-only
//   @if $widescreen-enabled and $fullhd-enabled
//     @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px)
//       @content

// =until-fullhd
//   @if $fullhd-enabled
//     @media screen and (max-width: $fullhd - 1px)
//       @content

// =fullhd
//   @if $fullhd-enabled
//     @media screen and (min-width: $fullhd)
//       @content

// // The container horizontal gap, which acts as the offset for breakpoints
// $gap: 32px !default
// // 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
// $tablet: 769px !default
// // 960px container + 4rem
// $desktop: 960px + (2 * $gap) !default
// // 1152px container + 4rem
// $widescreen: 1152px + (2 * $gap) !default
// $widescreen-enabled: true !default
// // 1344px container + 4rem
// $fullhd: 1344px + (2 * $gap) !default
// $fullhd-enabled: true !default

export const device = {
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,
}
